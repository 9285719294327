@font-face {
  font-family: "Capitaine";
  src: url("./shared/assets/font/FriendsCapitaine-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Capitaine";
  src: url("./shared/assets/font/FriendsCapitaine-RegularItalic.woff")
    format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Capitaine";
  src: url("./shared/assets/font/FriendsCapitaine-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Capitaine";
  src: url("./shared/assets/font/FriendsCapitaine-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
