@import "./shared/assets/font/font.scss";

html {
  font-family: "Capitaine", sans-serif;
}

body {
  margin: 0;
  padding: 0;

  background-color: #efefef;
}

@mixin selection {
  background: #f7b23391;
}

::-webkit-selection {
  @include selection();
}
::-moz-selection {
  @include selection();
}
::selection {
  @include selection();
}

:selection input {
  font-family: inherit;
  padding: 0.5rem 0.75rem;
}

.typography-bold {
  font-weight: 600 !important;
}

.app-picker {
  margin: 3rem 2rem;

  h4 {
    margin-bottom: 1.5rem;
  }

  [href] {
    text-decoration: none;
    width: fit-content;
  }

  .links {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  button {
    color: white;
    margin-bottom: 1rem;

    &.teacher {
      background-color: #f50057;
    }

    &.projector {
      background-color: #6200ee;
    }

    &.student {
      background-color: #2196f3;
    }
  }
}

body {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#root {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  height: 100%;
  width: 100%;
}
